@import "./style/printStyle.css";

body {
  .pace {
    .pace-activity {
      display: block;
      position: fixed;
      z-index: 2000;
      top: 35px;
      right: 5px;
      width: 20px;
      height: 20px;
      border: solid 3px transparent;
      border-top-color: #0d6efd;
      border-left-color: #0d6efd;
      border-radius: 10px;
    }
  }
}

* {
 /*noinspection CssNoGenericFontName*/
 /* font-family: "Lucida Sans Unicode";*/
  /*font-size: 99%!important;*/
  /*font-weight: 900 !important;*/
  /*@media print {*/
  /* !*noinspection CssNoGenericFontName*!*/
  /*  font-family: "Lucida Sans Unicode";*/
  /*  font-size: 9px!important;*/
  /*  font-weight: 900 !important;*/
  /*}*/
}


.ck-editor__editable {
  min-height: 200px !important;
}
/*:root {*/
/*  --bs-primary: #0e918a;*/
/*  --bs-primary-text-emphasis : #0e918a;*/
/*}*/

.ad-modal-cross {
  width: 54px;
  height: 54px;
  color: #0e918a;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}


.btn-outline-teal{
  color: #0e918a;
  border-color: #0e918a;
}

.btn-outline-teal:hover {
  background-color: #0e918a;
  color: #fff;
}

.btn-teal{
  background-color: #0e918a;
  border-color: #0e918a;
  color: white;
  /*padding: 0.375rem 0.75rem;*/

}

.btn-teal:hover {
  background-color: #0e918a;
  color: #fff;
  border-color: #0e918a;
}

.btn-teal:focus {
  background-color: #0e918a;
  color: #fff;
  border-color: #0e918a;
}
.btn-teal:focus-visible {
  background-color: #0e918a;
  color: #fff;
  border-color: #0e918a;
}

.bg-teal {
  background-color: #0e918a;
}



.navbar-brand img {
  width: 200px;
}

.custom-container {
  max-width: calc(100% - 600px);
  margin: 0 auto;
}

input[type=checkbox]:checked {
  background-color: #0e918a !important;
  border-color: #0e918a;
}
input[type=radio]:checked {
  background-color: #0e918a !important;
  border-color: #0e918a;
}


.preview-item {
  position: relative;
  overflow: hidden;
}

.img-cross i {
  position: absolute;
  top: 3px;
  right: 2px;
  color: white; /* Adjust the color as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  /*display: none; !* Initially hide the icon *!*/
}

.preview-item:hover .img-cross i {
  display: block; /* Display the icon when the parent is hovered */
}

/*.nav-primary.nav-tabs .nav-link.active {*/
/*  color: #008cff;*/
/*  border-color: #008cff #008cff #fff;*/
/*}*/

.border-teal{
  border-color:  #0e918a !important;
}

/*input[type="text"], input[type="password"], input[type="email"]{*/
/*  border-color: #0e918a;*/
/*}*/

/*input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus{*/
/*  !*border-color: #0e918a;*!*/
/*  box-shadow: 0 0 0 0.2rem #0e918a;*/
/*}*/


.sameWidthTable td {
  width: 25%; /* Adjust the width based on your design */
  word-wrap: break-word; /* Wrap text if it's too long */
}

.error-border {
  border: 1px solid red !important;
  border-radius: 5px !important;
}

  .error {
    color: red !important;
    font-size: 12px !important;
    margin: 0 !important;
    padding: 0 !important;
  }


.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
}