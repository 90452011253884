@media print {
    body  {
        visibility: hidden;
    }
    .qrCodePrint {
        visibility: visible !important;
    }
    .qrCodePrint .print_border{
        padding : 12px !important;
    }

    .print_body {
        visibility: visible !important;
        margin: 0 !important;
        padding: 10px !important;
    }

    hr {
        display: block;
        height: 1px;
        background: transparent;
        width: 100%;
        border: none;
        border-top: solid 1px black;
    }

    .page-break {
        page-break-before: always;
    }
}

